.section-faces {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 $spacing-40px;
    // overflow: hidden;

    &__wrapper {
        width: 100%;
        max-width: $max-width-sectionInnerMedium;
        margin-left: 16.5vw;
    }

    &__title,
    &__subtitle {
        line-height: $line-height-100p;
    }

    &__title {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin-bottom: $spacing-20px;

        .all {
            margin-left: $spacing-30px;
            padding: $spacing-10px $spacing-20px;
            text-transform: none;
            border-radius: 10px;
            border: 1px solid $darkSilver;
            background-color: $white;
            color: $primary;
            cursor: pointer;
            transition: border-color .2s ease, background-color .2s ease, color .2s ease;

            &:hover {
                border-color: $primary;
                background-color: $primary;
                color: $white;
            }
        }
    }

    &__subtitle {
        width: fit-content;
        position: relative;
        margin-bottom: $spacing-40px;
        color: $lightDark;

        .region {
            color: $darkSilver;
            cursor: pointer;
        }
    }

    &__location-list {
        display: none;
        position: absolute;
        top: 25px;
        right: -100px;
        z-index: $z-index-2;
        width: 200px;
        padding: $spacing-10px;
        background-color: $white;
        box-shadow: $modals-shadow-small;
        border-radius: 20px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease, visibility .3s ease;

        .list {
            &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 50px;
                padding: 0 $spacing-10px;
                margin-bottom: $spacing-5px;
                border-radius: 10px;
                transition: background-color .2s ease;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    background-color: $lighterSilver;
                }
            }

            &__item.active {
                position: relative; 
                background-color: $lighterSilver;
                cursor: pointer;

                .svg {
                    position: absolute;
                    right: 15px;
                }

                .list__title {
                    color: $primary;
                }
            }

            &__title {
                margin-bottom: $spacing-5px;
                color: $lightDark;
            }

            &__subtitle {
                color: $darkSilver;
            }
        }
    }

    &__location-list.db {
        display: block;
    }

    &__location-list.opened {
        opacity: 1;
        visibility: visible;
    }

    &__container {
        width: 100%;
        margin-bottom: $spacing-60px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 40px;

        .container {
            &__item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
            }

            &__img {
                display: block;
                width: 100%;
                height: 290px;
                margin-bottom: $spacing-20px;
                border-radius: 40px;
                overflow: hidden;
                cursor: pointer;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__bookmark {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                position: absolute;
                top: 15px;
                right: 15px;
                width: 40px;
                height: 40px;
                border: none;
                border-radius: 50%;
                background-color: transparent;
                cursor: pointer;
            }

            &__bookmark.active {
                .svg path {
                    fill: $primary;
                }
            }

            &__name {
                display: block;
                margin-bottom: $spacing-10px;
            }

            &__role {
                margin-bottom: $spacing-5px;
                color: $darkSilver;
            }

            &__country {
                margin-bottom: $spacing-20px;
            }

            &__info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 25px;
                margin-bottom: $spacing-20px;

                .item {
                    display: flex;
                    align-items: center;
                    margin-right: $spacing-15px;
                    cursor: pointer;
                }

                .svg {
                    //pixel perfect
                    margin-right: 2px;
                }

                .counter {
                    text-transform: uppercase;
                    color: $darkSilver;
                }
            }

            &__tags {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                margin-bottom: $spacing-20px;

                .tag {
                    display: block;
                    padding: $spacing-5px $spacing-10px;
                    border-radius: 10px;
                    background-color: $lightSilver;
                    transition: background-color .2s ease, color .2s ease;

                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }

            &__topics {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .counter {
                    color: $darkSilver;
                    line-height: $line-height-133p;
                }

                .plus {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 135px;
                    height: 50px;
                    padding: 0 $spacing-10px;
                    border-radius: 15px;
                    background-color: $lightSilver;
                    cursor: pointer;
                    transition: color .2s ease, background-color .2s ease;

                    .svg path {
                        transition: stroke .2s ease;
                    }

                    &:hover {
                        color: $white;
                        background-color: $primary;

                        .svg path {
                            stroke: $white;
                        }
                    }
                }
            }

            &__advertising {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                justify-content: space-around;
                gap: 40px;
                width: 100%;
                padding: 15px 0;

                .link {
                    width: 100%;
                }

                .img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &__form {
        display: none;
    }

    &__loadmore {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 175px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $spacing-50px;
        padding: 0 $spacing-20px;
        border-radius: 30px;
        border: 1px solid $darkSilver;
        background-color: $white;
        cursor: pointer;
        //tap selection offer
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: border-color .2s ease, background-color .2s ease;

        .text {
            transition: transform .2s ease, color .2s ease;
        }

        .svg {
            position: absolute;
            right: 15px;
            width: 40px;
            transition: opacity .2s ease, visibility .2s ease;

            rect {
                transition: fill .2s ease;
            }
        }

        .svg.dn {
            opacity: 0;
            visibility: hidden;
        }

        &:hover {
            border-color: $primary;
            background-color: $primary;

            .text {
                color: $white;
            }

            .svg {
                rect {
                    fill: $white;
                }
            }
        }
    }

    &__loadmore.active {
        .text {
            transform: translateX(-25px);
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: $max-width-sectionInner;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $spacing-50px;

        iframe {
            width: 100%;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .link {
            width: 100%;
            max-width: 730px;

            &:nth-child(1) {
                margin-right: $spacing-35px;
            }

            &:nth-child(2) {
                margin-left: $spacing-35px;
            }
        }

        .img {
            width: 100%;
            object-fit: cover;
        }
    }

    @media screen and (max-width: 5439px) {
        &__wrapper {
            margin-left: 6.3vw;
        }

        &__container {
            grid-template-columns: repeat(16, 1fr);
        }
    }

    @media screen and (max-width: 5119px) {
        &__wrapper {
            margin-left: 6.7vw;
        }

        &__container {
            grid-template-columns: repeat(15, 1fr);
        }
    }

    @media screen and (max-width: 4799px) {
        &__wrapper {
            margin-left: 7.1vw;
        }

        &__container {
            grid-template-columns: repeat(14, 1fr);
        }
    }

    @media screen and (max-width: 4479px) {
        &__wrapper {
            margin-left: 7.6vw;
        }

        &__container {
            grid-template-columns: repeat(13, 1fr);
        }
    }

    @media screen and (max-width: 4159px) {
        &__wrapper {
            margin-left: 8.2vw;
        }

        &__container {
            grid-template-columns: repeat(12, 1fr);
        }
    }

    @media screen and (max-width: 3839px) {
        &__wrapper {
            margin-left: 9.0vw;
        }

        &__container {
            grid-template-columns: repeat(11, 1fr);
        }
    }

    @media screen and (max-width: 3519px) {
        &__wrapper {
            margin-left: 9.9vw;
        }

        &__container {
            grid-template-columns: repeat(10, 1fr);
        }
    }

    @media screen and (max-width: 3199px) {
        &__wrapper {
            margin-left: 11.0vw;
        }

        &__container {
            grid-template-columns: repeat(9, 1fr);
        }
    }

    @media screen and (max-width: 2879px) {
        &__wrapper {
            margin-left: 12.3vw;
        }

        &__container {
            grid-template-columns: repeat(8, 1fr);
        }
    }

    @media screen and (max-width: 2559px) {
        &__wrapper {
            margin-left: 14.2vw;
        }

        &__container {
            grid-template-columns: repeat(7, 1fr);
        }
    }

    @media screen and (max-width: 2239px) {
        &__wrapper {
            margin-left: 16.5vw;
        }

        &__container {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    @media screen and (max-width: 1819px) {
        &__wrapper {
            margin-left: 19.5vw;
        }

        &__container {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    @media screen and (max-width: 1539px) {
        &__wrapper {
            margin-left: 24.2vw;
        }

        &__container {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media screen and (max-width: 1229px) {
        &__wrapper {
            margin-left: 0;
        }

        &__container {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (max-width: 939px) {
        &__container {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (max-width: $tabletXL) {
        .country__list {
            top: 130px;
            right: -10px;
            height: 390px;
        }

        &__bottom {
            flex-direction: column;

            .link {
                &:nth-child(1) {
                    margin-right: 0;
                    margin-bottom: $spacing-20px;
                }
    
                &:nth-child(2) {
                    margin-left: 0;
                }
            }
        }
    }

    @media screen and (max-width: $tablet) {
        padding: 0 $spacing-20px;

        &__title {
            font-size: 30px;
            margin-bottom: $spacing-10px;
        }

        &__loadmore {
            margin-bottom: $spacing-60px;
        }
    }

    @media screen and (max-width: $mobile) {
        &__title {
            align-items: flex-start;
            justify-content: space-between;

            .all {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                min-width: 70px;
                max-width: 70px;
                height: 30px;
                padding: 0;
                margin-left: 10px;
            }
        }

        &__location-list {
            right: -15px;
        }

        .country__list {
            right: -100px;
            width: 300px;
        }

        &__container {
            grid-template-columns: 1fr;
        }

        &__bottom {
            iframe {
                display: none;
            }

            .link {
                display: none;
            }
        }
    }
}