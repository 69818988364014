//TEXTS ===============================================================================================================

//Bold
.text-fsbd__bold {
    font-size: $fontSizeBoldDeafault;
    font-weight: 700;
}

.text-fsm__bold {
    font-size: $fontSizeMedium;
    font-weight: 700;
}

.text-fsb__bold {
    font-size: $fontSizeBig;
    font-weight: 700;
}

.text-fsd__bold {
    font-size: $fontSizeDeafault;
    font-weight: 700;
}

.text-fssb__bold {
    font-size: $fontSizeStrongBig;
    font-weight: 700;
}

.text-fsbb__bold {
    font-size: $fontSizeBolderBig;
    font-weight: 700;
}

.text-fsboldb__bold {
    font-size: $fontSizeBoldBig;
    font-weight: 700;
}

.text-fslb__bold {
    font-size: $fontSizeLightBig;
    font-weight: 700;
}

.text-fsbm__bold {
    font-size: $fontSizeBoldMedium;
    font-weight: 700;
}

.text-fslm__bold {
    font-size: $fontSizeLightMedium;
    font-weight: 700;
}

.text-fsms__bold {
    font-size: $fontSizeMaxSmall;
    font-weight: 700;
}

//Medium
.text-fsb__medium {
    font-size: $fontSizeBig;
    font-weight: 500;
}

.text-fsd__medium {
    font-size: $fontSizeDeafault;
    font-weight: 500;
}

.text-fsm__medium {
    font-size: $fontSizeMedium;
    font-weight: 500;
}

.text-fss__medium {
    font-size: $fontSizeSmall;
    font-weight: 500;
}

.text-fslm__medium {
    font-size: $fontSizeLightMedium;
    font-weight: 500;
}

.text-fsdb__medium {
    font-size: $fontSizeDefaultBig;
    font-weight: 500;
}

.text-fslb__medium {
    font-size: $fontSizeLightBig;
    font-weight: 500;
}

.text-fssb__medium {
    font-size: $fontSizeStrongBig;
    font-weight: 500;
}

//Regular
.text-fsm__regular {
    font-size: $fontSizeMedium;
    font-weight: 400;
}

.text-fsd__regular {
    font-size: $fontSizeDeafault;
    font-weight: 400;
}

.text-fsb__regular {
    font-size: $fontSizeBig;
    font-weight: 400;
}

.text-fss__regular {
    font-size: $fontSizeSmall;
    font-weight: 400;
}

.text-fslm__regular {
    font-size: $fontSizeLightMedium;
    font-weight: 400;
}

.text-fslb__regular {
    font-size: $fontSizeLightBig;
    font-weight: 400;
}

.text-fsld__regular {
    font-size: $fontSizeLightDefault;
    font-weight: 400;
}
//=====================================================================================================================
