//Adaptive ============================================================================================================
$mobileSM:                                             380px;
$mobile:                                               576px;
$tablet:                                               768px;
$tabletXL:                                             992px;

$laptop:                                              1200px;
$laptopXL:                                            1300px;
$desktop:                                             1400px;
$desktopXL:                                           1500px;
$desktopSpecificXL:                                   1740px;
$desktopSpecificXXL:                                  1820px;
//=====================================================================================================================

//Colors ==============================================================================================================
// $primaryLighten:                                   #FCF4F6;
// $primaryLight:                                     #FFFFFF;
$primary:                                          #FF3939;
$primaryDark:                                      #2260FF;

// $secondary:                                        #035FFE;

//White color palette
$white:                                            #FFFFFF;
$darkWhite:                                        #FAFCFF;
$darkenWhite:                                      #C4C4C4;

//Silver color palette
$lighterSilver:                                    #F5F5F5;
$lightSilver:                                      #E5E5E5;
$silver:                                           #585858;
$darkSilver:                                       #777E90;
$darkenSilver:                                     #efefef;

//Dark color palette
$lightDark:                                        #262626;
$dark:                                             #000000;

//Blue color palette
$lighterBlue:                                      #E4EFFF;
$lightBlue:                                        #DDEEFE;
$blue:                                             #5879C5;

//Yellow color palette
$yellow:                                           #FABC05;

//Orange color palette
$orange:                                           #FF5252;
$darkOrange:                                       #EB3030;

//Green color palette
$green:                                            #34A853;

//RGBA colors
//white
$rgba-white-10:                   rgba(255, 255, 255, 0.1);
$rgba-white-50:                   rgba(255, 255, 255, 0.5);

//dark
$rgba-dark-30:                          rgba(0, 0, 0, 0.3);
$rgba-dark-50:                          rgba(0, 0, 0, 0.5);
$rgba-dark-70:                          rgba(0, 0, 0, 0.7);

//box-shadows
$modals-shadow-mini:                                                                 0px 5px 20px rgba(0, 0, 0, 0.1);
$modals-shadow-small:                                                              0px 15px 40px rgba(0, 0, 0, 0.15);
$modals-shadow:                                                                    0px 15px 40px rgba(0, 0, 0, 0.25);
$modals-big-shadow:                                                            0px 20px 80px rgba(66, 133, 244, 0.3);

//filters
$drop-shadow-blue:                                                drop-shadow(0px 40px 80px rgba(66, 133, 244, 0.5));

//Gradients

//=====================================================================================================================

//Layout ==============================================================================================================
$max-width-sectionInner:                              1530px;
$max-width-sectionInnerMedium:                        1220px;
$max-width-910px:                                      910px;
$max-width-sectionInnerSmall:                          710px;
$max-width-600px:                                      600px;

//Spacing
$spacing-5px:                                            5px;
$spacing-10px:                                          10px;
$spacing-15px:                                          15px;
$spacing-20px:                                          20px;
$spacing-25px:                                          25px;
$spacing-30px:                                          30px;
$spacing-35px:                                          35px;
$spacing-40px:                                          40px;
$spacing-45px:                                          45px;
$spacing-50px:                                          50px;

$spacing-60px:                                          60px;
$spacing-70px:                                          70px;
$spacing-80px:                                          80px;
$spacing-90px:                                          90px;

$spacing-100px:                                        100px;
$spacing-110px:                                        110px;
$spacing-120px:                                        120px;
$spacing-130px:                                        130px;
$spacing-140px:                                        140px;
$spacing-150px:                                        150px;
$spacing-160px:                                        160px;
$spacing-170px:                                        170px;
$spacing-180px:                                        180px;
$spacing-190px:                                        190px;

$spacing-200px:                                        200px;
$spacing-220px:                                        220px;
$spacing-240px:                                        240px;
$spacing-250px:                                        250px;
$spacing-260px:                                        260px;
$spacing-270px:                                        270px;
$spacing-280px:                                        280px;

$spacing-310px:                                        310px;
$spacing-320px:                                        320px;
$spacing-330px:                                        330px;
$spacing-340px:                                        340px;
$spacing-350px:                                        350px;
$spacing-360px:                                        360px;

$spacing-400px:                                        400px;
//=====================================================================================================================

//Fonts ===============================================================================================================
$fontSizeSpecialButtonsBig:                             10px;

$fontSizeMicroMedium:                                   11px;
$fontSizeMicroBig:                                      13px;

$fontSizeSmall:                                         12px;
$fontSizeMedium:                                        14px;
$fontSizeDeafault:                                      16px;
$fontSizeBig:                                           18px;

$fontSizeLightSmall:                                    18px;
$fontSizeLightMedium:                                   20px;
$fontSizeLightDefault:                                  21px;
$fontSizeLightBig:                                      24px;

$fontSizeDefaultSmall:                                  24px;
$fontSizeDefaultMedium:                                 26px;
$fontSizeDefaultDefault:                                28px;
$fontSizeDefaultBig:                                    30px;

$fontSizeStrongSmall:                                   24px;
$fontSizeStrongMedium:                                  28px;
$fontSizeStrongDefault:                                 32px;
$fontSizeStrongBig:                                     36px;

$fontSizeBoldSmall:                                     36px;
$fontSizeBoldMedium:                                    40px;
$fontSizeBoldDeafault:                                  44px;
$fontSizeBoldBig:                                       48px;

$fontSizeBolderSmall:                                   32px;
$fontSizeBolderMedium:                                  48px;
$fontSizeBolderDeafault:                                56px;
$fontSizeBolderBig:                                     64px;

$fontSizeMaxSmall:                                      50px;
$fontSizeMaxMedium:                                     58px;
$fontSizeMaxDeafault:                                   66px;
$fontSizeMaxBig:                                        72px;

$fontSizeEpicSmall:                                     84px;
$fontSizeEpicMedium:                                    88px;
$fontSizeEpicDeafault:                                  92px;
$fontSizeEpicBig:                                       96px;
//=====================================================================================================================

//Line-heights ========================================================================================================
$line-height-100p:                                      1.00;
$line-height-110p:                                      1.10;
$line-height-113p:                                      1.13;
$line-height-116p:                                      1.16;
$line-height-119p:                                      1.19;
$line-height-122p:                                      1.22;
$line-height-125p:                                      1.25;
$line-height-129p:                                      1.29;
$line-height-133p:                                      1.33;
$line-height-140p:                                      1.40;
$line-height-144p:                                      1.44;
$line-height-142p:                                      1.42;
$line-height-146p:                                      1.46;
$line-height-150p:                                      1.50;
$line-height-155p:                                      1.55;
$line-height-160p:                                      1.60;
$line-height-166p:                                      1.66;
$line-height-171p:                                      1.71;
$line-height-177p:                                      1.77;
$line-height-188p:                                      1.88;
$line-height-200p:                                      2.00;
$line-height-225p:                                      2.25;
//=====================================================================================================================

//z-indexs ============================================================================================================
$z-index-minus-1:                                         -1;
$z-index-0:                                                0;
$z-index-1:                                                1;
$z-index-2:                                                2;
$z-index-3:                                                3;
$z-index-4:                                                4;
$z-index-5:                                                5;
//=====================================================================================================================

// FLASH JS THEME VARIABLES============================================================================================================

$white-color:                                             #fff;
$black-color:                                             #000;
$dark-color:                                              #2C3433;
$grey-color:                                              #9e9e9e;
$disabled-color:                                          #aaa;
$success-color:                                           #4CAF50;
$warning-color:                                           #FF851B;
$error-color:                                             #FF4136;
$info-color:                                              #0074D9;
$purple-color:                                            #8A2BE2;
//=====================================================================================================================