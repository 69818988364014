*{
  box-sizing: border-box;
}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}

nav,footer,header,aside{display: block;}

html,body{
  padding: 0;
  margin: 0;
}

a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
img{vertical-align: top;}
ul{list-style: none;padding-left: 0;margin-top: 0;}

h1, h2, h3{margin-top: 0;}