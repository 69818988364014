.topic-block {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 30px;
    right: 40px;
    z-index: $z-index-3;

    &__add {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 160px;
        height: 50px;
        padding: 0 $spacing-10px;
        border: none;
        border-radius: 30px;
        color: $white;
        background-color: $primary;
        cursor: pointer;

        .svg {

        }
    }

    @media screen and (max-width: $tablet) {
        right: 20px;
    }
}

.topic-block.up {
    bottom: 120px;
}