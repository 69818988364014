.cookies-modal {
    width: 100%;
    max-width: 620px;
    position: fixed;
    left: 30px;
    bottom: 40px;
    z-index: $z-index-3;
    padding: $spacing-30px $spacing-40px $spacing-40px;
    border-radius: 20px;
    box-shadow: $modals-shadow-small;
    background-color: $lightDark;

    &__title,
    &__text {
        color: $white;
    }

    &__title {
        margin-bottom: $spacing-20px;
    }

    &__text {
        width: 100%;
        margin-bottom: $spacing-40px;
        line-height: $line-height-133p;
    }

    &__btns {
        display: flex;
        align-items: center;

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 210px;
            height: 50px;
            border-radius: 10px;
            color: $white;
            cursor: pointer;
        }

        .cancel {
            margin-right: $spacing-10px;
            border: 1px solid $white;
            background-color: $lightDark;
        }

        .accept {
            margin-left: $spacing-10px;
            border: 1px solid $primary;
            background-color: $primary;
        }
    }

    @media screen and (max-width: $tabletXL) {
        left: 0;
        bottom: 0;
        max-width: 100%;
        border-radius: 0;
    }

    @media screen and (max-width: $tablet) {
        padding: $spacing-30px $spacing-20px;
        font-size: 20px;
    }
}

.cookies-modal.up {
    bottom: 120px;

    @media screen and (max-width: $tabletXL) {
        bottom: 0;
    }
}

.cookies-modal.dn {
    display: none;
}