.section-footer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 40px;
    width: 100%;
    background-color: $lighterSilver;
    padding: $spacing-60px $spacing-40px $spacing-30px;
    overflow: hidden;

    &__logo {
        .svg-mobile {
            display: none;
        }
    }

    &__list {
        margin-bottom: $spacing-45px;

        .list {
            &__title {
                display: block;
                text-transform: uppercase;
                margin-bottom: $spacing-25px;
            }

            &__item {
                display: flex;
                align-items: center;
                width: fit-content;
                margin-bottom: $spacing-10px;
                transition: color .2s ease;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    color: $primary;
                }

                .svg {
                    margin-right: $spacing-10px;
                }
            }
        }
    }

    &__copyright {
        width: 100%;
        grid-column: 1/5;
    }

    @media screen and (max-width: $tabletXL) {
        grid-template-columns: repeat(2, 1fr);

        &__logo {
            grid-column: 1/2;
            grid-row: 1/2;
        }

        &__list {
            &:nth-child(2) {
                grid-column: 1/2;
                grid-row: 1/3;
                margin-top: $spacing-70px;
            }

            &:nth-child(3) {
                grid-column: 1/2;
                grid-row: 3/4;
            }

            &:nth-child(4) {
                grid-column: 1/2;
                grid-row: 4/5;
            }

            &:nth-child(5) {
                grid-column: 2/3;
                grid-row: 1/5;
                margin-top: $spacing-70px;
            }
        }

        &__copyright {
            grid-column: 1/3;
            margin-top: $spacing-70px;
        }
    }

    @media screen and (max-width: $tablet) {
        padding: $spacing-60px $spacing-20px $spacing-30px;
    }

    @media screen and (max-width: $mobile) {
        &__logo {
            .svg {
                display: none;
            }

            .svg-mobile {
                display: block;
            }
        }

        &__list {
            &:nth-child(5) {
                grid-column: 1/2;
                grid-row: 4/5;
                margin-top: $spacing-130px;
            }
        }
    }
}

.section-footer.up {
    padding: $spacing-60px $spacing-40px $spacing-110px;

    @media screen and (max-width: $tablet) {
        padding: $spacing-60px $spacing-20px $spacing-140px;
    }

    @media screen and (max-width: $mobile) {
        padding: $spacing-60px $spacing-20px $spacing-220px;
    }

    @media screen and (max-width: 433px) {
        padding: $spacing-60px $spacing-20px $spacing-250px;
    }
}