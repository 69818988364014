//Imports =====================================================================
@import "variables";
@import "fonts";
@import "mixins";
@import "texts";
@import "normalize";
@import "flash-js";
//=============================================================================

//Common ======================================================================
html {
    font-size: $fontSizeMedium;
    font-weight: 400;
}

a {
    color: $dark;
}

body,
button {
    @include font-Fact;
}

body {
    width: 100vw;
    line-height: $line-height-100p;
    background-color: $white;
    color: $dark;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 10px;
        background-color: #f9f9fd;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $primary;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
        border-radius: 10px;
        background-color: #f9f9fd;
    }
}

.notif-block {
    position: relative;
}

#notificationsCounter {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $primary;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
}

button.dn {
    display: none !important;
}
//=============================================================================