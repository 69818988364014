//Imports =============================================================================================================
@import '../../global';

@import '../../components/index';
@import '../../components/countryList';

@import 'sectionPopular';
@import 'sectionFaces';
@import 'sectionRegion';
@import 'sectionStories';
//=====================================================================================================================

//Important elements and forms styles =================================================================================
#bookmark-form,
#private-bookmark-form,
#clear-search-history-form {
    display: none;
}

#hover-dark-block {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-3;
    width: 100vw;
    height: 100vh;
    background-color: $rgba-dark-50;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease, visibility .2s ease;
}

#hover-dark-block.db {
    display: block;
}

#hover-dark-block.opened {
    opacity: 1;
    visibility: visible;
}
//=====================================================================================================================

.success-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;

    .modal {
        width: 100%;
        max-width: 400px;
        padding: 20px;
        border-radius: 10px;
        background-color: #FFFFFF;
    }

    .close {
        display: block;
        margin-left: auto;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .text {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.40;
        text-align: center;
    }

    .minitext {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.40;
        text-align: center;
    }
}

.success-modal.dn {
    display: none;
}

.success-modal.active {
    opacity: 1;
    visibility: visible;
}
