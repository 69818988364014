.country {
    &__list {
        display: none;
        position: absolute;
        top: -70px;
        right: -420px;
        height: 570px;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: $z-index-2;
        width: 400px;
        padding: $spacing-10px;
        border-radius: 10px;
        background-color: $white;
        box-shadow: $modals-shadow;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease, visibility .3s ease;

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f9f9fd;
        }
    
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $primary;
        }
    
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            border-radius: 10px;
            background-color: #f9f9fd;
        }
    }

    &__list.db {
        display: block;
    }

    &__list.open {
        opacity: 1;
        visibility: visible;
    }

    &__item {
        height: 40px;
        padding: 0 $spacing-10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-radius: 10px;
        color: $silver;

        &:hover {
            color: $lightDark;
            background-color: $lighterSilver;
        }

        .check {
            display: none;
        }
    }

    &__item.active {
        color: $dark;

        .check {
            display: block;
        }
    }

    &__item.hidden {
        display: none;
    }

    &__search {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $dark;
        border-radius: 10px;
        padding-left: $spacing-10px;
        padding-right: $spacing-10px;
        margin-bottom: $spacing-15px;
        cursor: default;
    }

    &__search-iput {
        width: 70%;
        color: $dark;
        background-color: $white;
        padding: 0;
        margin: 0;
        border: none;
    }

    &__search-btn {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $primary;
        border-radius: 50%;
        background-color: $primary;
        cursor: pointer;
        transition: border-color .2s ease, background-color .2s ease;

        .fa-search {
            color: $white;
            transition: color .2s ease;
        }

        &:hover {
            border-color: $dark;
            background-color: $white;
            
            .fa-search {
                color: $primary;
            }
        }
    }
}