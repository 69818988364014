.section-stories {
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: 100%;
    padding: 0 $spacing-40px;
    // overflow: hidden;

    &__wrapper {
        width: 100%;
        max-width: $max-width-sectionInnerMedium;
        margin-left: 16.5vw;
    }

    &__title,
    &__subtitle {
        line-height: $line-height-100p;
    }

    &__title {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin-bottom: $spacing-20px;

        .all {
            margin-left: $spacing-30px;
            padding: $spacing-10px $spacing-20px;
            text-transform: none;
            border-radius: 10px;
            border: 1px solid $darkSilver;
            background-color: $white;
            color: $primary;
            cursor: pointer;
            transition: border-color .2s ease, background-color .2s ease, color .2s ease;

            &:hover {
                border-color: $primary;
                background-color: $primary;
                color: $white;
            }
        }
    }

    &__subtitle {
        width: fit-content;
        position: relative;
        margin-bottom: $spacing-30px;
        color: $lightDark;

        .region {
            color: $darkSilver;
            cursor: pointer;
        }
    }

    &__location-list {
        display: none;
        position: absolute;
        top: 25px;
        right: -100px;
        z-index: $z-index-2;
        width: 200px;
        padding: $spacing-10px;
        background-color: $white;
        box-shadow: $modals-shadow-small;
        border-radius: 20px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease, visibility .3s ease;

        .list {
            &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 50px;
                padding: 0 $spacing-10px;
                margin-bottom: $spacing-5px;
                border-radius: 10px;
                transition: background-color .2s ease;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    background-color: $lighterSilver;
                }
            }

            &__item.active {
                position: relative; 
                background-color: $lighterSilver;
                cursor: pointer;

                .svg {
                    position: absolute;
                    right: 15px;
                }

                .list__title {
                    color: $primary;
                }
            }

            &__title {
                margin-bottom: $spacing-5px;
                color: $lightDark;
            }

            &__subtitle {
                color: $darkSilver;
            }
        }
    }

    &__location-list.db {
        display: block;
    }

    &__location-list.opened {
        opacity: 1;
        visibility: visible;
    }

    &__container {
        width: 100%;
        margin-bottom: $spacing-60px;
        column-width: 15vw;
        column-gap: 20px;

        .container {
            &__item {
                display: inline-block;
                position: relative;
                z-index: $z-index-0;
                width: 100%;
                padding: $spacing-20px;
                background-color: $white;
                border: 1px solid $darkenWhite;
                border-radius: 40px;
                margin-bottom: $spacing-20px;
            }

            &__item.private {
                z-index: $z-index-1;
            }

            &__top {
                display: flex;
                align-items: center;
                margin-bottom: $spacing-20px;
            }

            &__img {
                object-fit: cover;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: $spacing-10px;
            }

            &__name {
                margin-bottom: $spacing-5px;
            }

            &__dtime {
                color: $darkSilver;
            }

            &__bookmark {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 40px;
                height: 40px;
                margin-left: auto;
                margin-right: 0;
                border: none;
                border-radius: 50%;
                background-color: transparent;

                .svg {
                    cursor: pointer;
                }
            }

            &__bookmark.active {
                .svg path {
                    fill: $primary;
                }
            }

            &__bookmark.private {
                background-color: $lighterSilver;
            }

            &__private {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50px;
                left: 0;
                width: 200px;
                height: 70px;
                border-radius: 20px;
                box-shadow: $modals-shadow-small;
                background-color: $white;
                opacity: 0;
                visibility: hidden;
                transition: opacity .2s ease, visibility .2s ease;

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 180px;
                    height: 50px;
                    padding: 0 $spacing-10px;
                    border: none;
                    border-radius: 10px;
                    background-color: $lighterSilver;
                    color: $lightDark;
                    cursor: pointer;

                    .text {
                        transition: transform .2s ease;
                    }
            
                    .svg {
                        position: absolute;
                        right: 15px;
                        width: 40px;
                        transition: opacity .2s ease, visibility .2s ease;
                    }
            
                    .svg.dn {
                        opacity: 0;
                        visibility: hidden;
                    }
                }

                .btn.active {
                    .text {
                        transform: translateX(-25px);
                    }
                }
            }

            &__private.opened {
                opacity: 1;
                visibility: visible;
            }

            &__link {
                display: block;
                width: 100%;
                margin-bottom: 10px;
                padding: 10px;
                background: #F5F5F5;
                border-radius: 20px;

                .title,
                .link {
                    word-break: break-word;
                }

                .title {
                    margin-bottom: 10px;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1.33;
                }

                .link {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.14;
                    color: #777E90;
                }
            }

            &__text {
                // overflow: hidden;
                // height: 75px;
                width: 100%;
                line-height: $line-height-133p;
                word-break: break-word;
                margin-bottom: $spacing-30px;
            }

            &__bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .item {
                    display: flex;
                    align-items: center;
                    margin-right: $spacing-15px;
                    cursor: pointer;
                }

                .svg {
                    //pixel perfect
                    margin-right: 2px;
                }

                .counter {
                    text-transform: uppercase;
                    color: $darkSilver;
                }
            }

            &__content {
                width: 100%;
                margin-bottom: $spacing-20px;

                .img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            &__link {
                width: 100%;
                margin-bottom: 10px;
                background: #F5F5F5;
                border-radius: 20px;

                .title {
                    padding: 10px 10px 0;
                    margin-bottom: 10px;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    word-break: break-word;
                }

                .link {
                    display: block;
                    padding: 0 10px 10px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #777E90;
                    word-break: break-word;
                }
            }

            &__video {
                display: flex;
                position: relative;
                cursor: pointer;
                
                .button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(4px);
                }

                .button.hidden {
                    display: none;
                }

                .video {
                    
                }
            }

            &__advertising {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                width: 100%;

                .link {
                    width: 100%;
                    margin-bottom: $spacing-40px;
                }

                .img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &__container.small {
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        .container__item {
            height: fit-content;
        }
    }

    &__form {
        display: none;
    }

    &__loadmore {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 175px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $spacing-50px;
        padding: 0 $spacing-20px;
        border-radius: 30px;
        border: 1px solid $darkSilver;
        background-color: $white;
        cursor: pointer;
        //tap selection offer
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: border-color .2s ease, background-color .2s ease;

        .text {
            transition: transform .2s ease, color .2s ease;
        }

        .svg {
            position: absolute;
            right: 15px;
            width: 40px;
            transition: opacity .2s ease, visibility .2s ease;

            rect {
                transition: fill .2s ease;
            }
        }

        .svg.dn {
            opacity: 0;
            visibility: hidden;
        }

        &:hover {
            border-color: $primary;
            background-color: $primary;

            .text {
                color: $white;
            }

            .svg {
                rect {
                    fill: $white;
                }
            }
        }
    }

    &__loadmore.active {
        .text {
            transform: translateX(-25px);
        }
    }

    @media screen and (max-width: 5439px) {
        &__wrapper {
            margin-left: 6.3vw;
        }

        &__container {
            column-width: 5.5vw;
        }
    }

    @media screen and (max-width: 5119px) {
        &__wrapper {
            margin-left: 6.7vw;
        }

        &__container {
            column-width: 6vw;
        }
    }

    @media screen and (max-width: 4799px) {
        &__wrapper {
            margin-left: 7.1vw;
        }

        &__container {
            column-width: 6.5vw;
        }
    }

    @media screen and (max-width: 4479px) {
        &__wrapper {
            margin-left: 7.6vw;
        }

        &__container {
            column-width: 7vw;
        }
    }

    @media screen and (max-width: 4159px) {
        &__wrapper {
            margin-left: 8.2vw;
        }

        &__container {
            column-width: 7.5vw;
        }
    }

    @media screen and (max-width: 3839px) {
        &__wrapper {
            margin-left: 9.0vw;
        }

        &__container {
            column-width: 8vw;
        }
    }

    @media screen and (max-width: 3519px) {
        &__wrapper {
            margin-left: 9.9vw;
        }

        &__container {
            column-width: 9vw;
        }
    }

    @media screen and (max-width: 3199px) {
        &__wrapper {
            margin-left: 11.0vw;
        }

        &__container {
            column-width: 10vw;
        }
    }

    @media screen and (max-width: 2879px) {
        &__wrapper {
            margin-left: 12.3vw;
        }

        &__container {
            column-width: 11vw;
        }
    }

    @media screen and (max-width: 2559px) {
        &__wrapper {
            margin-left: 14.2vw;
        }

        &__container {
            column-width: 13vw;
        }
    }

    @media screen and (max-width: 2239px) {
        &__wrapper {
            margin-left: 16.5vw;
        }

        &__container {
            column-width: 14vw;
        }
    }

    @media screen and (max-width: 1819px) {
        &__wrapper {
            margin-left: 19.5vw;
        }

        &__container {
            column-width: 16vw;
        }
    }

    @media screen and (max-width: 1539px) {
        &__wrapper {
            margin-left: 24.2vw;
        }

        &__container {
            column-width: 18vw;
        }
    }

    @media screen and (max-width: 1229px) {
        &__wrapper {
            margin-left: 0;
        }

        &__container {
            column-width: 23vw;
        }
    }

    @media screen and (max-width: 939px) {
        &__container {
            column-width: 30vw;
        }
    }

    @media screen and (max-width: $tabletXL) {
        .country__list {
            top: 130px;
            right: -10px;
            height: 390px;
        }
    }

    @media screen and (max-width: $tablet) {
        padding: 0 $spacing-20px;

        &__title {
            font-size: 30px;
            // margin-bottom: $spacing-10px;
        }
    }

    @media screen and (max-width: $mobile) {
        &__title {
            align-items: flex-start;
            justify-content: space-between;

            .all {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                min-width: 70px;
                max-width: 70px;
                height: 30px;
                padding: 0;
                margin-left: 10px;
            }
        }

        &__container {
            column-width: 70vw;
        }

        &__container.small {
            display: block;
            grid-template-columns: unset;
        }

        &__location-list {
            right: -15px;
        }

        .country__list {
            right: -100px;
            width: 300px;
        }
    }
}