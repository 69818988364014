.info-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    padding: 0 $spacing-40px;
    position: fixed;
    z-index: $z-index-3;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $primary;

    &__close {
        position: absolute;
        right: 40px;
        cursor: pointer;
    }

    &__title {
        color: $white;

        .dark {
            color: $dark;
        }

        .dark {
            color: $dark;
            transition: opacity .5s ease, visibility .5s ease;

            .white {
                color: $white;
            }
        }

        .dark.hidden {
            opacity: 0;
            visibility: hidden;
        }
    }

    &__singup {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 140px;
        margin-right: $spacing-60px;
        border-radius: 10px;
        background-color: $white;
    }

    @media screen and (max-width: $tablet) {
        height: fit-content;
        padding: $spacing-30px $spacing-20px;
    }

    @media screen and (max-width: $mobile) {
        flex-direction: column;

        &__close {
            top: 15px;
            right: 15px;
        }

        &__title {
            width: 100%;
            padding-right: $spacing-20px;
            margin-bottom: $spacing-20px;
            font-size: 18px;
        }

        &__singup {
            margin-right: auto;
        }
    }
}

.info-block.dn {
    display: none;
}