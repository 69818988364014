.section-header {
    display: flex;
    align-items: center;
    position: relative;
    z-index: $z-index-4;
    width: 100%;
    margin-bottom: $spacing-60px;
    padding: $spacing-10px $spacing-40px;
    border-bottom: 1px solid $darkenWhite;
    background-color: $white;
    // overflow: hidden;

    &__burger {
        display: none;
        min-width: 24px;
        margin-right: $spacing-30px;
        cursor: pointer;
    }

    &__mobile-menu {
        display: none;
        width: 300px;
        height: 100%;
        max-height: 100vh;
        overflow-y: auto;
        position: fixed;
        z-index: $z-index-4;
        top: 0;
        left: 0;
        bottom: 0;
        box-shadow: $modals-shadow-small;
        background-color: $white;
        transform: translateX(-100%);
        transition: transform .3s ease;

        .menu {
            &__top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: $spacing-30px $spacing-40px $spacing-90px;
            }

            &__close {
                cursor: pointer;
            }

            &__item {
                width: fit-content;
                display: block;
                margin-bottom: $spacing-15px;
                margin-left: $spacing-40px;
                text-transform: uppercase;
                transition: color .2s ease;

                &:hover {
                    color: $primary;
                }
            }

            &__bottom {
                width: calc(100% - 80px);
                display: none;
                align-items: center;
                justify-content: space-between;
                margin: 0 $spacing-40px;
                position: absolute;
                bottom: 30px;
            }

            &__singup,
            &__login {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 45px;
                width: 100%;
                border-radius: 10px;
            }

            &__singup {
                margin-right: $spacing-10px;
                background-color: $primary;
                color: $white;
            }

            &__login {
                margin-left: $spacing-10px;
                background-color: $lighterSilver;
            }
        }
    }

    &__mobile-menu.db {
        display: block;
    }

    &__mobile-menu.opened {
        transform: translateX(0);
    }

    &__logo {
        margin-right: $spacing-80px;

        .svg-mobile {
            display: none;
        }
    }

    &__menu {
        width: 100%;
        display: flex;
        align-items: center;

        .menu {
            &__item {
                margin-right: $spacing-20px;
                cursor: pointer;
                text-transform: uppercase;
                white-space: nowrap;
                transition: color .2s ease;

                &:hover {
                    color: $primary;
                }

                &:nth-child(4) {
                    margin-right: $spacing-10px;
                }
            }

            &__more {
                position: relative;
                display: flex;
                align-items: center;
            }

            &__btn path {
                transition: stroke .3s ease;
            }
    
            &__more-list {
                display: none;
                width: 180px;
                position: absolute;
                top: 30px;
                left: 0;
                z-index: $z-index-3;
                padding: $spacing-20px;
                border-radius: 10px;
                background-color: $white;
                box-shadow: $modals-shadow-small;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s ease, visibility .3s ease;
            }

            &__more-list.db {
                display: block;
            }
    
            &__more-list.opened {
                opacity: 1;
                visibility: visible;
            }
    
            &__more-item {
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: $spacing-10px;
                text-transform: uppercase;
                transition: color .2s ease;

                &:hover {
                    color: $primary;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__more.active {
                .menu__btn path {
                    stroke: $primary;
                }
            }

            &__btn {
                cursor: pointer;
            }
        }
    }

    &__search {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 55px;
        max-width: 445px;
        margin-left: auto;
        margin-right: $spacing-20px;
        border: 1px solid $dark;
        border-radius: 10px;
        background-color: $dark;
        transition: background-color .2s ease;

        .arrow-left {
            display: none;
        }

        .form-container {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .btn {
            width: 20px;
            height: 20px;
            margin-left: $spacing-25px;
            margin-right: $spacing-25px;
            cursor: pointer;

            path {
                transition: stroke .2s ease;
            }
        }

        .input {
            @include font-AvenirNextCyr;
            width: 100%;
            margin-right: $spacing-25px;
            border: none;
            color: $white;
            background-color: $dark;
            transition: color .2s ease, background-color .2s ease;

            &::placeholder {
                color: $white;
            }
        }

        .list {
            display: none;
            position: absolute;
            top: 75px;
            left: 0;
            z-index: $z-index-1;
            width: 600px;
            padding: $spacing-20px;
            border-radius: 10px;
            background-color: $white;
            opacity: 0;
            visibility: hidden;
            transition: opacity .2s ease, visibility .2s ease;

            &-default.dn {
                display: none;
            }

            &-clear {
                position: absolute;
                top: 20px;
                right: 20px;
                color: $silver;
                cursor: pointer;
            }

            &-minititle {
                margin-bottom: $spacing-20px;
            }

            &-history {
                margin-bottom: $spacing-30px;

                .item {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    margin-bottom: $spacing-15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .clock {
                    margin-right: $spacing-10px;
                }
            }

            &-changer {
                .item {
                    width: fit-content;
                    cursor: pointer;
                    margin-bottom: $spacing-15px;
                    transition: color .2s ease;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .item.active {
                    color: $primary;
                    cursor: default;
                }
            }

            &-results {
                //results item (people)
                .item {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-bottom: $spacing-10px;
                }

                .img {
                    min-width: 40px;
                    max-width: 40px;
                    height: 40px;
                    margin-right: $spacing-10px;
                    overflow: hidden;
                    object-fit: cover;
                    border-radius: 50%;
                }

                .block {
                    margin-right: $spacing-30px;
                }

                .name {
                    margin-bottom: $spacing-5px;
                }

                .from,
                .info,
                .tags {
                    color: $darkSilver;
                }

                .info {
                    margin-bottom: $spacing-5px;
                }

                .svg {
                    margin-left: auto;
                }

                .all {
                    display: block;
                    width: fit-content;
                    margin: $spacing-10px auto 0;
                    color: $primary;
                }

                //results item (topics)
                .item-topics {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-bottom: $spacing-20px;

                    &__title {
                        margin-bottom: $spacing-5px;
                    }

                    &__block {
                        display: flex;
                        align-items: center;
                    }

                    &__name {
                        margin-right: $spacing-10px;
                    }

                    &__dtime {
                        color: $darkSilver;
                    }
                }

                //results item (news)
                .item-news {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-bottom: $spacing-20px;

                    &__block {
                        display: flex;
                        align-items: center;
                        margin-bottom: $spacing-5px;
                    }

                    &__name {
                        margin-right: $spacing-10px;
                    }

                    &__dtime {
                        color: $darkSilver;
                    }

                    &__title {
                        margin-bottom: $spacing-5px;
                    }

                    &__link {
                        color: $darkSilver;
                    }
                }
            }

            &-results.dn {
                display: none;
            }

            &-loading__svg {
                display: block;
                width: 50%;
                margin: 0 auto;
            }

            &-loading__svg.dn {
                display: none;
            }
        }

        .list.db {
            display: block;
        }
        
        .list.opened {
            opacity: 1;
            visibility: visible;
        }

        .search-button {
            position: absolute;
            right: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 51px;
            border: none;
            border-radius: 10px 9px 9px 10px;
            font-size: 16px;
            font-weight: 500;
            background-color: $primary;
            color: $white;
            opacity: 0;
            visibility: hidden;
            transition: opacity .2s ease, visibility .2s ease;
            cursor: pointer;
        }
    }

    &__search.focused {
        background-color: $white;

        .btn {
            path {
                stroke: $dark;
            }
        }

        .input {
            color: $dark;
            background-color: $white;
        }

        .search-button {
            opacity: 1;
            visibility: visible;
        }
    }

    &__mobile-search {
        display: none;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: $spacing-25px;
    }

    &__language {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: $spacing-20px;
        cursor: pointer;

        .svg {
            margin-right: $spacing-10px;
        }

        .svg path {
            transition: stroke .3s ease;
        }

        .text {
            @include font-AvenirNextCyr;
            color: $darkSilver;
            transition: color .3s ease;
        }

        .language {
            &__list {
                display: none;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 15px;
                grid-row-gap: 10px;
                position: absolute;
                top: 60px;
                left: -60px;
                z-index: $z-index-3;
                max-height: 420px;
                overflow-x: hidden;
                overflow-y: auto;
                padding: $spacing-20px;
                border-radius: 10px;
                background-color: $white;
                box-shadow: $modals-shadow-small;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s ease, visibility .3s ease;

                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: $lightSilver;
                    border-radius: 10px;
                }
            
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: $darkSilver;
                }
            
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
                    border-radius: 10px;
                    background-color: $lightSilver;
                }
            }

            &__list.db {
                display: grid;
            }

            &__list.opened {
                opacity: 1;
                visibility: visible;
            }

            &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 120px;
                height: 50px;
                padding: 0 $spacing-10px;
                border-radius: 10px;
                transition: border-color .2s ease;

                &:hover {
                    background-color: $lighterSilver;
                }
            }

            &__item.active {
                background-color: $lighterSilver;
                cursor: default;
            }

            &__title {
                margin-bottom: $spacing-5px;
                color: $lightDark;
            }

            &__subtitle {
                color: $darkSilver;
            }
        }
    }

    &__language.active {
        .text {
            color: $dark;
        }

        .svg path {
            stroke: $dark;
        }
    }

    &__edit,
    &__tags {
        display: flex;
        align-items: center;

        .btn {
            cursor: pointer;
        }
    }

    &__edit {
        position: relative;
        margin-right: $spacing-20px;

        .btn path {
            transition: stroke .3s ease, fill .3s ease;
        }

        .edit {
            &__list {
                display: none;
                width: 150px;
                height: 40px;
                align-items: center;
                position: absolute;
                top: 40px;
                left: 0;
                z-index: $z-index-3;
                padding: 0 $spacing-20px;
                border-radius: 10px;
                background-color: $dark;
                box-shadow: $modals-shadow-small;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s ease, visibility .3s ease;
            }

            &__list.db {
                display: flex;
            }

            &__list.opened {
                opacity: 1;
                visibility: visible;
            }

            &__item {
                width: 100%;
                color: $white;
                cursor: pointer;
            }
        }
    }

    &__edit.active {
        .btn path {
            &:nth-child(1) {
                fill: $dark;
            }

            &:nth-child(2),
            &:nth-child(3) {
                stroke: $dark;
            }
        }
    }

    &__edit.big {
        min-width: 170px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $spacing-20px;
        border: 1px solid $darkSilver;
        border-radius: 10px;
        cursor: pointer;
    }

    &__tags {
        position: relative;
        margin-right: $spacing-30px;
        
        .btn path {
            transition: stroke .3s ease;
        }

        .tags {
            &__list {
                display: none;
                width: 290px;
                position: absolute;
                top: 60px;
                left: -60px;
                z-index: $z-index-3;
                max-height: 660px;
                overflow-x: hidden;
                overflow-y: auto;
                padding: $spacing-20px;
                border-radius: 10px;
                background-color: $white;
                box-shadow: $modals-shadow-small;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s ease, visibility .3s ease;

                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: $lightSilver;
                    border-radius: 10px;
                }
            
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: $darkSilver;
                }
            
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
                    border-radius: 10px;
                    background-color: $lightSilver;
                }
            }

            &__list.db {
                display: block;
            }

            &__list.opened {
                opacity: 1;
                visibility: visible;
            }

            &__list.big {
                left: unset;
                right: 0;
            }

            &__item {
                display: flex;
                align-items: center;
                width: 100%;
                height: 40px;
                padding: 0 $spacing-10px;
                margin-bottom: $spacing-10px;
                border-radius: 10px;
                transition: border-color .2s ease;

                &:hover {
                    background-color: $lighterSilver;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__item.active {
                background-color: $lighterSilver;
                cursor: default;
            }
        }
    }

    &__tags.active {
        .btn path {
            stroke: $dark;
        }
    }

    &__singup,
    &__login {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 100%;
        border-radius: 10px;
    }

    &__singup {
        max-width: 140px;
        margin-right: $spacing-10px;
        background-color: $primary;
        color: $white;
    }

    &__login {
        max-width: 80px;
        background-color: $lighterSilver;
    }

    &__ding {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $spacing-25px;

        .svg {
            cursor: pointer;
        }
    }

    &__profile {
        display: flex;
        align-items: center;
        justify-content: center;

        .profile {
            &__btn {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 45px;
                width: 45px;
                border-radius: 50%;
                border: 1px solid $dark;
                overflow: hidden;
                cursor: pointer;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__modal {
                display: none;
                width: 290px;
                position: absolute;
                top: 90px;
                right: 40px;
                z-index: $z-index-3;
                padding: $spacing-10px $spacing-10px $spacing-20px;
                border-radius: 20px;
                background-color: $white;
                box-shadow: $modals-shadow-small;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s ease, visibility .3s ease;
            }

            &__modal.db {
                display: block;
            }

            &__modal.opened {
                opacity: 1;
                visibility: visible;
            }

            &__deatils {
                display: flex;
                align-items: center;
                width: 100%;
                padding: $spacing-10px;
                margin-bottom: $spacing-20px;
                border-radius: 20px;
                background-color: $lighterSilver;
            }

            &__img {
                width: 60px;
                height: 60px;
                margin-right: $spacing-10px;
                object-fit: cover;
                border-radius: 50%;
            }

            &__info {
                .name {
                    margin-bottom: $spacing-5px;
                }

                .status {
                    margin-bottom: $spacing-5px;
                    color: $darkSilver;
                }

                .nickname {
                    word-break: break-word;
                    color: $darkSilver;
                }
            }

            &__link {
                display: block;
                width: fit-content;
                margin-left: $spacing-10px;
                margin-bottom: $spacing-20px;
                transition: color .2s ease;

                &:hover {
                    color: $primary;
                }

                &:nth-child(6) {
                    margin-bottom: $spacing-40px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__mobile-language {
        display: none;
        align-items: center;
        position: relative;
        cursor: pointer;

        .svg {
            margin-right: $spacing-10px;
        }

        .svg path {
            transition: stroke .3s ease;
        }

        .text {
            @include font-AvenirNextCyr;
            color: $darkSilver;
            transition: color .3s ease;
        }

        .language {
            &__list {
                display: none;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 15px;
                grid-row-gap: 10px;
                position: absolute;
                top: 60px;
                right: 0;
                z-index: $z-index-3;
                max-height: 420px;
                overflow-x: hidden;
                overflow-y: auto;
                padding: $spacing-20px;
                border-radius: 10px;
                background-color: $white;
                box-shadow: $modals-shadow-small;
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s ease, visibility .3s ease;

                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: $lightSilver;
                    border-radius: 10px;
                }
            
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: $darkSilver;
                }
            
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
                    border-radius: 10px;
                    background-color: $lightSilver;
                }
            }

            &__list.db {
                display: grid;
            }

            &__list.opened {
                opacity: 1;
                visibility: visible;
            }

            &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 120px;
                height: 50px;
                padding: 0 $spacing-10px;
                border-radius: 10px;
                transition: border-color .2s ease;

                &:hover {
                    background-color: $lighterSilver;
                }
            }

            &__item.active {
                background-color: $lighterSilver;
                cursor: default;
            }

            &__title {
                margin-bottom: $spacing-5px;
                color: $lightDark;
            }

            &__subtitle {
                color: $darkSilver;
            }
        }
    }

    &__language.active {
        .text {
            color: $dark;
        }

        .svg path {
            stroke: $dark;
        }
    }

    @media screen and (min-width: 1921px) {
        &__search {
            max-width: 100%;
            margin-left: $spacing-60px;
        }

        &__menu {
            .menu {
                &__item {
                    &:nth-child(4) {
                        margin-right: $spacing-20px;
                    }
                }

                &__btn {
                    display: none;
                }

                &__more-list {
                    width: fit-content;
                    position: relative;
                    top: unset;
                    left: unset;
                    /* z-index: unset; */
                    padding: 0;
                    box-shadow: unset;
                    opacity: 1;
                    visibility: visible;
                    display: flex;
                    align-items: center;
                }

                &__more-item {
                    margin-bottom: 0;
                    margin-right: $spacing-20px;
                    font-size: 16px;
                    font-weight: 700;

                    &:nth-child(2) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $desktopSpecificXXL) {
        &__language {
            .svg {
                width: 24px;
                height: 24px;
                margin-right: 0;
            }

            .text {
                display: none;
            }
        }
    }

    @media screen and (max-width: $desktopSpecificXL) {
        &__burger {
            display: block;
        }

        &__logo {
            margin-right: $spacing-30px;
        }

        &__menu {
            display: none;

            .menu {
                &__item,
                &__more {
                    display: none;
                }
            }
        }

        &__search {
            max-width: 100%;
        }
    }

    @media screen and (max-width: $laptop) {
        &__mobile-menu {
            width: 375px;
            
            .menu {
                &__bottom {
                    display: flex;
                }
            }
        }

        &__edit {
            .edit {
                &__list {
                    top: 60px;
                    left: unset;
                    right: 0;
                }
            }
        }

        &__edit.big {
            min-width: unset;
            height: unset;
            border: none;
            padding: 0;
            border-radius: 0;

            .btn {
                path {
                    &:nth-child(1) {
                        fill: $darkSilver;
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        stroke: $darkSilver;
                    }
                }
            }

            .text {
                display: none;
            }
        }

        &__language,
        &__singup,
        &__login {
            display: none;
        }

        &__mobile-language {
            display: flex;
        }

        &__tags {
            margin-right: 0;

            .tags {
                &__list {
                    left: unset;
                    right: 0;
                }
            }
        }

        &__ding {
            margin-left: $spacing-20px;
        }
    }

    @media screen and (max-width: $tabletXL) {
        &__search {
            position: fixed;
            top: 0;
            left: 0;
            z-index: $z-index-1;
            //pixel perfect
            height: 67px;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid $darkenWhite;
            background-color: $white;
            opacity: 0;
            visibility: hidden;
            transition: opacity .2s ease, visibility .2s ease;

            .arrow-left {
                display: block;
                margin-left: $spacing-20px;
            }

            .form-container {
                height: 40px;
                margin-left: $spacing-20px;
                margin-right: $spacing-20px;
                border-radius: 10px;
                border: 1px solid $dark;
            }

            .svg {
                path {
                    stroke: $dark;
                }
            }

            .input {
                color: $dark;
                background-color: $white;

                &::placeholder {
                    color: $dark;
                }
            }

            .list {
                position: fixed;
                //pixel perfect
                top: 67px;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100vw;
                border-radius: 0;
            }

            .search-button {
                right: 22px;
                height: 36px;
            }
        }

        &__search.focused {
            opacity: 1;
            visibility: visible;
        }

        &__mobile-search {
            display: flex;
        }
    }

    @media screen and (max-width: $tablet) {
        padding: $spacing-10px $spacing-20px;

        &__search {
            .input {
                &::placeholder {
                    display: none;
                    opacity: 0;
                    visibility: hidden;
                }
            }
            
            .list-results {
                .item,
                .item-topics,
                .item-news {
                    position: relative;
                }

                .item {
                    display: grid;
                }

                .img {
                    grid-column: 1/2;
                    grid-row: 1/2;
                }

                .block {
                    margin-right: $spacing-50px;
                    word-break: break-word;
                }

                .block:nth-child(2) {
                    grid-column: 1/2;
                    grid-row: 1/2;
                    margin-left: $spacing-50px;
                }

                .block:nth-child(3) {
                    grid-column: 1/2;
                    grid-row: 2/3;
                    margin-top: $spacing-10px;
                }

                .svg {
                    position: absolute;
                    right: 0;
                }
            }
        }

        &__mobile-menu {
            .menu {
                &__top {
                    margin: $spacing-30px $spacing-20px $spacing-90px;
                }

                &__item {
                    margin-left: $spacing-20px;
                }

                &__bottom {
                    width: calc(100% - 40px);
                    margin: 0 $spacing-20px;
                }
            }
        }

        &__logo {
            .svg {
                display: none;
            }

            .svg-mobile {
                display: block;
            }
        }

        &__profile {
            .profile {
                &__modal {
                    right: 20px;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        margin-bottom: $spacing-40px;

        &__burger {
            margin-right: $spacing-20px;
        }

        &__mobile-menu {
            width: 100%;
        }

        &__ding {
            display: none !important;
        }

        &__profile {
            margin-left: $spacing-25px;
        }
    }
}